* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    transition: all 0.3s;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.06);
    transition: all 0.3s;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
  }
  /* 滚动条滑块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.12);
    transition: all 0.3s;
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
}

.sider-trigger {
    position: relative;
    transform: translateY(0);
    cursor: pointer;
    z-index: 1;
    font-size: 20px;
    right: 0;
}

.login-footer {
    position: absolute;
    bottom: 0;
    width: 96%;
    padding: 10px 0 10px 0;
    text-align: center;
}

.user-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon-style {
    margin-left: 10px;
    font-size: 20px;
}

.login-button {
    width: 80%;
    margin-bottom: 10px;
}

.login-button.google {
    background-color: #4285f4;
    border-color: #4285f4;
}

.login-button.facebook {
    background-color: #3b5998;
    border-color: #3b5998;
}

.login-button.microsoft {
    background-color: #000;
    border-color: #000;
}

.edit-button {
    width: 85px;
}

.conversation-name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
}

