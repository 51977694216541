.jump-btn {
  border-radius: 2rem;
  padding: .5rem 1.5rem;
}

.date {
  font-size: .8rem;
  opacity: .5;
}

.intro-text{
  text-align: center;
  font-size: 18px;
}

.textButton{
  word-break: break-all;
  padding: 40px 10px;
  margin: 0 8px;
  max-width: 25%;
  white-space: normal;
}