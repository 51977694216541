.chat-footer {
  background-color: #e1e1e1;
  margin: 1rem 2rem;
  border-radius: 3rem;
  padding: .6rem;
}

.chat-footer-dark {
  background-color: #2A4D4A;
  margin: 1rem 2rem;
  border-radius: 3rem;
  padding: .6rem;
}